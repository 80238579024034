import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UserPublicProfile } from './models';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  usersEndpoint = `${environment.lambda_api_endpoint}/${environment.lambda_endpoints.users}`;
  constructor(private http: HttpClient) {}

  getUserPublicProfile(userId: string): Observable<string | UserPublicProfile> {
    return this.http.get(`${this.usersEndpoint}/getuserpublicprofile?userId=${userId}`).pipe(
      map((data: UserPublicProfile) => data),
      catchError(() => of('Error, could not find publisher'))
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

import { SessionStorage, Alert, Alerts } from '@app/@shared';

import { PrivateBidData, Bid, Question, Planholder } from './models';

@Injectable({
  providedIn: 'root'
})
export class BidService {
  bidsEndpoint = `${environment.lambda_api_endpoint}/${environment.lambda_endpoints.projects}`;
  @SessionStorage privateBidData: PrivateBidData;

  constructor(private http: HttpClient, private router: Router) {}

  getPublicBid(bidId: string): Observable<string | Bid> {
    return this.http.get(`${this.bidsEndpoint}/getpublicproject?projectId=${bidId}`).pipe(map((data: Bid) => data));
  }

  @Alert({ type: Alerts.SUCCESS, message: 'Bid is now being tracked.', dismissable: true })
  startBidTracking(bidId: string): Observable<string | any> {
    return this.http.get(`projects/TrackProject/${bidId}`).pipe(map((data: any) => data));
  }

  checkFileLimiter(bidId: string): Observable<string | any> {
    return this.http.get(`${this.bidsEndpoint}/checkdownloadlimiter?projectId=${bidId}`).pipe(
      map((data: any) => data),
      catchError(() => of('Error, could not find bid'))
    );
  }

  addPlanholder(projectId: string, action: string): Observable<any> {
    return this.http.get(`projects/AddPlanholder/${action}/${projectId}`).pipe(
      map((data: any) => data),
      catchError(() => of('Error, could not find bid'))
    );
  }

  getUserQAEmailStatus(bidId: string): Observable<string | any> {
    return this.http.get(`projects/UserQAEmailStatus/${bidId}`).pipe(
      map((status: any) => JSON.parse(status?.data)),
      catchError(() => of('Error, could not find bid'))
    );
  }

  @Alert({ type: Alerts.SUCCESS, message: 'You will now receive emails for all new questions and answers.', dismissable: true })
  setAddQAEmail(bidId: string): Observable<string | any> {
    const url = `projects/AddToQAEmail/${bidId}`;
    return this.http.put(url, {}, {});
  }

  @Alert({
    type: Alerts.SUCCESS,
    message: 'You have been removed from receiving emails for new all questions and answers.',
    dismissable: true
  })
  setRemoveQAEmail(bidId: string): Observable<string | any> {
    const url = `projects/RemoveUserToQAEmail/${bidId}`;
    return this.http.put(url, {});
  }

  getQAExcelReportLink(bidId: string): Observable<string | any> {
    return of(`https://authtest.civcast.com/api/FileStorage/GetProjectQAExcelReport/${bidId}`).pipe(
      catchError(() => of('Error, could not find bid'))
    );
  }

  saveBidQuestion(bidId: string, question: Question): Observable<string | Question> {
    return this.http.post(`projects/AddQuestion/${bidId}`, question).pipe(
      map((data: Question) => data),
      catchError(() => of('Error, could not save question'))
    );
  }

  @Alert({ type: Alerts.SUCCESS, message: 'Bid is not being tracked.', dismissable: true })
  stopBidTracking(bidId: string): Observable<string | any> {
    return this.http.get(`user/RemoveTracking/${bidId}`).pipe(map((data: any) => data));
  }

  @Alert({
    type: Alerts.SUCCESS,
    message: 'Bid has been removed from your interested projects list.',
    dismissable: true
  })
  untrackBidInterest(bidId: string): Observable<string | any> {
    return this.http.get(`user/RemoveProjectInterest/${bidId}`).pipe(map((data: any) => data));
  }

  @Alert({
    type: Alerts.SUCCESS,
    message: 'Bid has been added to your interested bids list.',
    dismissable: true
  })
  trackBidInterest(bidId: string): Observable<string | Planholder> {
    return this.http.get(`projects/TrackProjectInterest/${bidId}`).pipe(map((data: Planholder) => data));
  }

  @Alert({
    type: Alerts.SUCCESS,
    message: 'You are now interested in bidding on project.',
    dismissable: true
  })
  setBidding(bidId: string): Observable<string | any> {
    const url = `projects/SetBidding/${bidId}`;
    return this.http.put(url, {}).pipe(map((status: any) => JSON.parse(status?.data)));
  }

  @Alert({
    type: Alerts.SUCCESS,
    message: 'You have indicated that you will not bid on project.',
    dismissable: true
  })
  setNoBidding(bidId: string): Observable<string | any> {
    const url = `projects/SetNoBidding/${bidId}`;
    return this.http.put(url, {}).pipe(map((status: any) => JSON.parse(status?.data)));
  }

  getPlanHolderExcelReportLink(bidId: string): Observable<string | any> {
    return of(`https://authtest.civcast.com/api/FileStorage/GetProjectPlanholderExcel/${bidId}`).pipe(
      catchError(() => of('Error, could not find bid'))
    );
  }

  @Alert({
    type: Alerts.SUCCESS,
    message: 'You have updated your planholder info for this project',
    dismissable: true
  })
  updatePlanholderCompanyType(companyType: any, bidId: string): Observable<string | any> {
    const url = `${this.bidsEndpoint}/planholder?projectId=${bidId}`;
    return this.http.patch(url, companyType).pipe(map((data: any) => data));
  }
}
